import { CustomUserClaim, CustomUserClaims } from "@okta/okta-auth-js";

export interface CustomClaims {
  employeeId: CustomUserClaim | CustomUserClaim[];
  nandosRoles: CustomUserClaim | CustomUserClaim[] | undefined;
  //TODO-This is has not been tested
  restaurant: CustomUserClaim | CustomUserClaim[] | undefined;
}
export const customClaimsMap = async (
  oktaClaim: CustomUserClaims
): Promise<CustomClaims> => {
  return {
    employeeId: oktaClaim.employee_id,
    nandosRoles: oktaClaim.nandos_roles,
    //TODO-This is has not been tested
    restaurant: oktaClaim.nandos_my_restaurant,
  };
};
