import React from "react";
import { strings } from "../../assets/res/strings";
import { isSafari } from "react-device-detect";

type CustomToggle = {
  lToggle: boolean;
  action?: () => void;
};

export const CustomToggle = ({ lToggle, action }: CustomToggle) => {
  const [leftToggle, setLeftToggle] = React.useState(lToggle);
  const toggle = () => {
    setLeftToggle(!leftToggle);
    if (action) action();
  };

  //styles as react obj for svg props
  const textStyles = (): React.CSSProperties => {
    const styles = {
      fontFamily: "barlowRegular",
      fontWeight: "bold",
      fontSize: "20",
      fill: "white",
    };
    return isSafari ? { ...styles, letterSpacing: "-0.5px" } : styles; // Fixes for safari adding letter spacing when using calculated bold fonts
  };

  return (
    <div className={"toggle-buttons"}>
      <svg onClick={toggle} width="340" height="58">
        <rect x="0" y="0" width="340" height="52" rx="29" />
        <rect
          x={leftToggle ? "4" : "174"}
          y="3.5"
          width="162"
          height="44.5"
          rx="26"
          style={{ fill: "#CE0A24" }} //dark red
        />
        <text x="47" y="32" style={textStyles()}>
          {strings.common.pending}
        </text>
        <text x="212" y="32" style={textStyles()}>
          {strings.common.complete}
        </text>
      </svg>
    </div>
  );
};
