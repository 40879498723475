export const SAVE_TRAININGS = `
  mutation Mutation(
    $employeeLearnings: EmployeeLearningRecordInput
  ) {
    upsertEmployeeLearnings( 
      input: $employeeLearnings
    ) {
        employeeId
        learningId
        completedAt
        updatedAt
    }
  }
`;

export const SUBMIT_TRAININGS = `
  mutation Mutation(
    $employeeCertificate: EmployeeCertificateRecordInput
    ) {
      upsertEmployeeCertificate(
        input: $employeeCertificate
        ) {
            employeeId
            certificateId
            submittedAt
          }
  }
`;

export const SUBMIT_MANAGER_COMPLIANCE = `
  mutation Mutation(
    $managerCertificate: ManagerCertificateRecordInput
    ) {
      approveEmployeesCertificates(
        input: $managerCertificate
        ) {
            approvedAt
            approvedBy
            employeeId
            certificateId
          }
  }
`;
