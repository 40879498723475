import React from "react";
import { useOktaAuth } from "@okta/okta-react";
import { toRelativeUrl } from "@okta/okta-auth-js";
import { Outlet, Navigate } from "react-router-dom";
import NandosLogo from "../assets/images/NandosLogo";
// import LoadingOverlay from "./LoadingOverlay";
export const isManager = (): boolean => {
  //React Context is out of scope in here
  if (!localStorage.getItem("okta-token-storage")) return false;
  const localTokenStorage = JSON.parse(
    localStorage.getItem("okta-token-storage") || ""
  );
  const nandosRoles = localTokenStorage?.idToken?.claims?.nandos_roles || [];
  return !!nandosRoles.find((nr: string) => nr === "restaurant_manager");
};

export const RequiresAuth: React.FC = () => {
  const { oktaAuth } = useOktaAuth();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [authenticated, setAuth] = React.useState<boolean>(false);

  let originalUri = "/";

  React.useEffect(() => {
    setLoading(true);

    (async () => {
      const res = await oktaAuth.isAuthenticated();
      setLoading(false);
      setAuth(res);
      if (res === false) {
        originalUri = toRelativeUrl(
          window.location.href,
          window.location.origin
        );
        oktaAuth.signInWithRedirect();
      }
    })();
  }, []);

  //restricting manager's path
  if (loading || !authenticated) {
    return <NandosLogo style={{ minHeight: "100vh" }} />;
  }
  if (window.location.href.toString().includes("manager")) {
    return isManager() ? <Outlet /> : <Navigate to={originalUri} />;
  }
  return <Outlet />;
};
