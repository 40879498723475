import { ButtonPage } from "../buttonPage/ButtonPage";
import * as Styles from "../../pages/Page.styles";
import { ButtonEnum } from "../buttonPage/buttonType";

type Header = {
  className: string;
  heading: string;
  containerClassName?: string;
  page: string;
  subHeadingClassName?: string;
  subHeading?: string;
  date?: string;
};

const Header = ({
  className,
  heading,
  containerClassName,
  page,
  subHeadingClassName,
  subHeading,
  date,
}: Header) => {
  return (
    <div className={className}>
      <h1 style={Styles.Title}>{heading}</h1>
      <div className={containerClassName}>
        <ButtonPage
          text={undefined}
          page={page}
          buttonType={ButtonEnum.backButton}
        />
        {subHeading ? (
          <p className={subHeadingClassName}>
            {subHeading}{" "}
            {date && <span className={subHeadingClassName}>{date}</span>}
          </p>
        ) : undefined}
      </div>
    </div>
  );
};

export default Header;
