/* eslint-disable array-callback-return */
import React from "react";
import { FormSection, FormItem } from "../../models/FormTypes";
import "./FormPage.css";
import { SectionTable } from "../../components/form/FormSection";
import { ToastMessage } from "../../components/message/ToastMessage";
import { convertTimeToSavedLearningDate } from "../../services/time";
import { strings } from "../../assets/res/strings";
import { LearningService } from "../../services/learningService";
import { CustomButton } from "../../components/button/CustomButton";
import { CustomCheckbox } from "../../components/checkbox/CustomCheckbox";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import Header from "../../components/manager/Header";
import Spinner from "../../components/spinner/Spinner";
const teamsCompliance = "..";

const FormPage = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();

  const [formData, setFormData] = React.useState<Array<FormSection>>();
  const [showConfirmation, setShowConfirmation] = React.useState<boolean>();
  const [submitted, setSubmitted] = React.useState(false);
  const [approved, setApproved] = React.useState(false);
  const [showSave, setShowSave] = React.useState(false);
  const [submitError, setSubmitError] = React.useState(false);
  const [toast, setToast] = React.useState<{
    message: string;
    error: boolean;
    isOpen: boolean;
  }>({ message: "", error: false, isOpen: false });
  const [lastSaved, setLastSaved] = React.useState<string>("");
  const lService = new LearningService();

  React.useEffect(() => {
    (async () => {
      if (state?.firstName && state?.lastName) {
        const result = await lService.getEmployeeRecordLearnings(
          userId as string
        );
        setFormData(result.sections);
        setSubmitted(result.submitted);
        setApproved(result.approved);
        if (result.submitted || result.approved) {
          if (result.submitted && !result.approved) {
            setLastSaved(
              convertTimeToSavedLearningDate(
                new Date(result.certificates?.[0]?.submittedAt)
              )
            );
          } else {
            setLastSaved(
              convertTimeToSavedLearningDate(
                new Date(result.certificates?.[0]?.approvedAt)
              )
            );
          }
          //if we have a last saved date, we know we can save
          setShowSave(true);
        }
      } else {
        navigate("/manager/teamsCompliance");
      }
    })();
  }, []);

  const resetToast = () => {
    setToast({
      message: "",
      error: false,
      isOpen: false,
    });
  };

  const [confirmChecklist, setConfirmChecklist] = React.useState([
    {
      id: 1,
      text: strings.pages.manager.teamsCompliancePage.confirmText1,
      completed: false,
    },
    {
      id: 2,
      text: strings.pages.manager.teamsCompliancePage.confirmText2,
      completed: false,
    },
  ]);

  const completeRequiredTrainingSection = React.useMemo<{
    [key: string]: boolean;
  }>(() => {
    return (
      formData?.reduce((acc, sectionGroup) => {
        return {
          ...acc,
          [sectionGroup.name]: sectionGroup.modules.reduce(
            //@ts-ignore
            (boolReq, formItem) => {
              if (formItem.isRequired && !formItem.complete) {
                setSubmitted(false);
                return false;
              }
              return boolReq;
            },
            true
          ),
        };
      }, {}) || {}
    );
  }, [formData]);

  const requiredTrainingComplete = React.useMemo(() => {
    return Object.values(completeRequiredTrainingSection).every((bool) => bool);
  }, [completeRequiredTrainingSection]);

  const confirmValid = React.useMemo(
    () => confirmChecklist.every((val) => val.completed),
    [confirmChecklist]
  );

  const scrollToSection = () => {
    const section = document.querySelector("#message");
    section?.scrollIntoView({ behavior: "smooth" });
  };

  const onSubmit = React.useCallback(() => {
    resetToast();
    if (!requiredTrainingComplete) {
      setToast({
        message: strings.alerts.formIncompleteError,
        error: true,
        isOpen: true,
      });
      setSubmitError(true);
      setSubmitted(false);
      scrollToSection();
      return;
    }

    setShowConfirmation(true);
  }, [requiredTrainingComplete]);
  const approveCertificate = React.useCallback(async () => {
    let saveRes = await lService.approveCertificate(userId as string);
    if (saveRes === true) {
      navigate("../", {
        state: {
          success: true,
        },
      });
    } else {
      handleCancelClick(); // to make greyed out trainings to black once Confirm fails
      navigate("../", {
        state: {
          success: false,
        },
      });
    }
  }, [formData]);

  const onConfirm = React.useCallback(async () => {
    if (confirmValid) {
      await approveCertificate();
    }
  }, [confirmValid]);

  const handleClick = React.useCallback(
    (module: FormItem) => {
      const newData = JSON.parse(JSON.stringify(formData));
      const { modules } = newData[module.categoryId - 1];
      const index = modules.findIndex(({ id }: FormItem) => module.id === id);
      modules[index].complete = !module.complete;
      modules[index].error = false;

      setShowSave(true);
      setFormData(newData);
    },
    [formData]
  );

  const handleConfirmChecked = React.useCallback(
    (item: (typeof confirmChecklist)[0]) => {
      setConfirmChecklist((values) =>
        values.map((aItem) => {
          if (aItem.id == item.id) {
            return { ...aItem, completed: !aItem.completed };
          }
          return aItem;
        })
      );
    },
    [setConfirmChecklist]
  );

  const handleCancelClick = React.useCallback(() => {
    setConfirmChecklist((values) =>
      values.map((aItem) => {
        return { ...aItem, completed: false };
      })
    );
    setSubmitted(false);
    setShowConfirmation(false);
  }, [setConfirmChecklist]);

  return (
    <>
      {/* POPUP */}
      {showConfirmation && requiredTrainingComplete && (
        <>
          <div className="confirm-section">
            <div className="confirm-section-content">
              <div className="confirm-section-header">
                <p>{strings.pages.form.confirmHeading}</p>
              </div>
              <div className="confirm-checkboxes">
                {confirmChecklist.map((item: any) => {
                  const { completed, id } = item;
                  const readyText = item.text.replace(
                    "<Nandoca>",
                    `${state?.firstName} ${state?.lastName}`
                  );
                  return (
                    <CustomCheckbox
                      id={id}
                      text={readyText}
                      completed={completed}
                      action={() => handleConfirmChecked(item)}
                      conditionChecker={false}
                      errorText={""}
                    />
                  );
                })}
              </div>
            </div>
            <div className="confirm-buttons-section">
              <CustomButton
                text={strings.common.confirmUpper}
                className={"btn-confirm" + (confirmValid ? "" : " disabled")}
                disabled={!confirmValid}
                action={onConfirm}
              />
              <CustomButton
                text={strings.common.cancelUpper}
                className="btn-cancel"
                action={handleCancelClick}
              />
            </div>
          </div>
          <div className="overlay"></div>
        </>
      )}
      {/* END POPUP */}
      <div
        className={
          "page-container" + (showConfirmation ? " tablet-desktop-only" : "")
        }
      >
        <Header
          className={
            "head-container" + (showConfirmation ? " tablet-desktop-only" : "")
          }
          heading={`${
            state?.firstName +
            " " +
            state?.lastName +
            " " +
            strings.pages.form.managerHeading
          }`}
          containerClassName={"nav-link-container-form"}
          page={teamsCompliance}
          subHeadingClassName={"last-saved-date"}
          subHeading={`${approved ? "Completed" : "Submitted"} on`}
          date={lastSaved}
        />
        {!formData ? (
          <Spinner />
        ) : (
          <div id="message" className="section-tables-wrapper">
            <ToastMessage
              message={toast.message}
              error={toast.error}
              isOpen={toast.isOpen}
              closeMessage={() => setToast({ ...toast, isOpen: false })}
            />
            <div className="section-tables">
              {formData &&
                formData?.map((section: FormSection) => (
                  <SectionTable
                    error={
                      submitError &&
                      !completeRequiredTrainingSection[section.name]
                    }
                    submitted={submitted}
                    section={section}
                    handleClick={handleClick}
                    confirmed={confirmValid}
                    managerView={true}
                  />
                ))}
            </div>
            <p className="nandoca-compliance-msg">
              {`${state?.firstName} ${state?.lastName}`} has agreed they are
              safe and compliant and have completed the training themselves.
            </p>
            {!approved && (
              <div className="submit-buttons-wrapper">
                <div className={"section-table-container save-btn-only"}>
                  <CustomButton
                    text={strings.common.approve}
                    className={"btn-approve"}
                    disabled={!showSave}
                    action={async () => {
                      onSubmit();
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default FormPage;
