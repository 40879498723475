import "./ButtonPage.styles.css";
import { buttons } from "./buttonType";
import { useNavigate } from "react-router-dom";

export type ButtonPageType = {
  buttonType: number;
  text: string | undefined;
  page: string;
  state?: any;
};

export const ButtonPage = ({
  buttonType,
  text,
  page,
  state,
}: ButtonPageType) => {
  const navigate = useNavigate();

  return (
    <span
      onClick={() => navigate(page, { state })}
      className={buttons[buttonType].className}
    >
      {text}
      {buttons[buttonType].img}
    </span>
  );
};
