import React from "react";

export const Section: React.CSSProperties = {
  paddingBottom: "35px",
  float: "left",
  width: "100%",
};

export const Title: React.CSSProperties = {
  fontFamily: "nandosHand",
  marginTop: 0,
  fontWeight: 400,
  fontSize: "28px",
  lineHeight: "28px",
};

export const Subheading: React.CSSProperties = {
  maxWidth: "90%",
  margin: "0px auto",
  paddingLeft: "30px",
  marginBottom: "30px",
};

export const ButtonContainer: React.CSSProperties = {
  paddingTop: "30px",
};

export const Popup: React.CSSProperties = {
  position: "fixed",
  top: "100px",
  left: "20%",
  width: "230px",
  height: "212px",
  padding: "0px auto",
  backgroundColor: "#FFFFFF",
};

export const PopupText: React.CSSProperties = {
  padding: "10px 10px 0px 10px",
};
