import React from "react";
import "./Spinner.styles.css";

type Spinner = {
  containerClass?: string;
  spinnerClass?: string;
};

export const Spinner = ({ containerClass, spinnerClass }: Spinner) => {
  return (
    <div className={containerClass ?? "spinner-container"}>
      <svg
        width="120"
        height="120"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
        className={spinnerClass ?? "Standard-spinner"}
      >
        <rect
          x="0"
          y="0"
          width="100"
          height="100"
          fill="none"
          className="bk"
        ></rect>
        <rect
          x="46.5"
          y="40"
          width="7"
          height="20"
          rx="5"
          ry="5"
          fill="#000000"
          transform="rotate(0 50 50) translate(0 -30)"
        >
          {" "}
          <animate
            attributeName="opacity"
            from="1"
            to="0"
            dur="1.5s"
            begin="0s"
            repeatCount="indefinite"
          />
        </rect>
        <rect
          x="46.5"
          y="40"
          width="7"
          height="20"
          rx="5"
          ry="5"
          fill="#000000"
          transform="rotate(30 50 50) translate(0 -30)"
        >
          {" "}
          <animate
            attributeName="opacity"
            from="1"
            to="0"
            dur="1.5s"
            begin="0.125s"
            repeatCount="indefinite"
          />
        </rect>
        <rect
          x="46.5"
          y="40"
          width="7"
          height="20"
          rx="5"
          ry="5"
          fill="#000000"
          transform="rotate(60 50 50) translate(0 -30)"
        >
          {" "}
          <animate
            attributeName="opacity"
            from="1"
            to="0"
            dur="1.5s"
            begin="0.25s"
            repeatCount="indefinite"
          />
        </rect>
        <rect
          x="46.5"
          y="40"
          width="7"
          height="20"
          rx="5"
          ry="5"
          fill="#000000"
          transform="rotate(90 50 50) translate(0 -30)"
        >
          {" "}
          <animate
            attributeName="opacity"
            from="1"
            to="0"
            dur="1.5s"
            begin="0.375s"
            repeatCount="indefinite"
          />
        </rect>
        <rect
          x="46.5"
          y="40"
          width="7"
          height="20"
          rx="5"
          ry="5"
          fill="#000000"
          transform="rotate(120 50 50) translate(0 -30)"
        >
          {" "}
          <animate
            attributeName="opacity"
            from="1"
            to="0"
            dur="1.5s"
            begin="0.5s"
            repeatCount="indefinite"
          />
        </rect>
        <rect
          x="46.5"
          y="40"
          width="7"
          height="20"
          rx="5"
          ry="5"
          fill="#000000"
          transform="rotate(150 50 50) translate(0 -30)"
        >
          {" "}
          <animate
            attributeName="opacity"
            from="1"
            to="0"
            dur="1.5s"
            begin="0.625s"
            repeatCount="indefinite"
          />
        </rect>
        <rect
          x="46.5"
          y="40"
          width="7"
          height="20"
          rx="5"
          ry="5"
          fill="#000000"
          transform="rotate(180 50 50) translate(0 -30)"
        >
          {" "}
          <animate
            attributeName="opacity"
            from="1"
            to="0"
            dur="1.5s"
            begin="0.75s"
            repeatCount="indefinite"
          />
        </rect>
        <rect
          x="46.5"
          y="40"
          width="7"
          height="20"
          rx="5"
          ry="5"
          fill="#000000"
          transform="rotate(210 50 50) translate(0 -30)"
        >
          {" "}
          <animate
            attributeName="opacity"
            from="1"
            to="0"
            dur="1.5s"
            begin="0.875s"
            repeatCount="indefinite"
          />
        </rect>
        <rect
          x="46.5"
          y="40"
          width="7"
          height="20"
          rx="5"
          ry="5"
          fill="#000000"
          transform="rotate(240 50 50) translate(0 -30)"
        >
          {" "}
          <animate
            attributeName="opacity"
            from="1"
            to="0"
            dur="1.5s"
            begin="1s"
            repeatCount="indefinite"
          />
        </rect>
        <rect
          x="46.5"
          y="40"
          width="7"
          height="20"
          rx="5"
          ry="5"
          fill="#000000"
          transform="rotate(270 50 50) translate(0 -30)"
        >
          {" "}
          <animate
            attributeName="opacity"
            from="1"
            to="0"
            dur="1.5s"
            begin="1.125s"
            repeatCount="indefinite"
          />
        </rect>
        <rect
          x="46.5"
          y="40"
          width="7"
          height="20"
          rx="5"
          ry="5"
          fill="#000000"
          transform="rotate(300 50 50) translate(0 -30)"
        >
          {" "}
          <animate
            attributeName="opacity"
            from="1"
            to="0"
            dur="1.5s"
            begin="1.25s"
            repeatCount="indefinite"
          />
        </rect>
        <rect
          x="46.5"
          y="40"
          width="7"
          height="20"
          rx="5"
          ry="5"
          fill="#000000"
          transform="rotate(330 50 50) translate(0 -30)"
        >
          {" "}
          <animate
            attributeName="opacity"
            from="1"
            to="0"
            dur="1.5s"
            begin="1.375s"
            repeatCount="indefinite"
          />
        </rect>
      </svg>
    </div>
  );
};

export default Spinner;
