import { strings } from "../../assets/res/strings";
import * as Styles from "../Page.styles";
import "../form/FormPage.css";
import "./ManagerPage.styles.css";
import { ButtonPage } from "../../components/buttonPage/ButtonPage";
import { ButtonEnum } from "../../components/buttonPage/buttonType";
const MyRecordPage = "../";
const TeamsCompliancePage = "/manager/teamsCompliance";

export const ManagerPage = () => {
  return (
    <>
      <div className={"page-container"}>
        <h1 style={Styles.Title}>{strings.pages.manager.heading}</h1>
        <div className={"nav-link-container"}>
          <p className={"nav-item"}>
            {strings.pages.manager.managerPage.subHeading}
          </p>
        </div>
        <div>
          <ButtonPage
            text={strings.pages.manager.managerPage.option1}
            page={MyRecordPage}
            buttonType={ButtonEnum.chevronButton}
          />
        </div>
        <div>
          <ButtonPage
            text={strings.pages.manager.managerPage.option2}
            page={TeamsCompliancePage}
            buttonType={ButtonEnum.chevronButton}
          />
        </div>
      </div>
    </>
  );
};
