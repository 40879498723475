export const convertTimeToSavedLearningDate = (date: Date) => {
  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const dateString =
    daysOfWeek[date.getDay()] + " " + date.toLocaleDateString();
  return dateString;
};

export const returnTime = (date: Date) => {
  const timeString = " " + date.toLocaleTimeString([], { timeStyle: "short" });
  return timeString;
};
