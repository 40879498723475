import React from "react";
import { FormItem, FormSection } from "../../models/FormTypes";
import "../../pages/form/FormPage.css";
import classnames from "classnames";
import { TRAINER } from "../../models";
import { CustomCheckbox } from "../checkbox/CustomCheckbox";
import { strings } from "../../assets/res/strings";
import ChevronIcon from "../../assets/images/formPageImages/ChevronIcon";

export type SectionTableProps = {
  section: FormSection;
  handleClick: Function;
  error: boolean;
  submitted: boolean;
  confirmed: boolean;
  managerView: boolean;
};

export const SectionTable = React.memo((props: SectionTableProps) => {
  const { section, handleClick, error, submitted, confirmed, managerView } =
    props;
  const [open, setOpen] = React.useState(true);
  const toggleContent = React.useCallback(() => setOpen((open) => !open), []);

  // Get the unique trainer headings
  const availableHeadings = [...new Set(section.modules.map((m) => m.trainer))];

  const formSubSection = (subSection: FormItem[], title: String = "") => {
    return (
      <>
        {title ? <h4 className="trainer-heading">{title}</h4> : null}
        {subSection.map((module) => {
          const { id, name, complete, isRequired } = module;
          const errorText = error && isRequired && !complete ? "error-txt" : "";
          return (
            <CustomCheckbox
              key={id}
              id={id}
              text={name}
              completed={complete}
              action={() => handleClick(module)}
              conditionChecker={managerView ? true : submitted && isRequired}
              errorText={errorText}
            />
          );
        })}
      </>
    );
  };

  const getTraining = (training: TRAINER) =>
    section.modules.filter((m) => m.trainer.toString() === TRAINER[training]);
  return (
    <div className={classnames("section-table-container", { open: open })}>
      <div className="heading" onClick={toggleContent}>
        <h3>{section.name}</h3>
        <ChevronIcon className="chevron" />
      </div>
      <div
        className={classnames(`section-table-options`, { hidden: !open })}
        data-testid="section-table-options"
      >
        {availableHeadings.length > 1 ? (
          <>
            {formSubSection(
              getTraining(TRAINER.MANAGER),
              strings.pages.form.subtitle1
            )}
            {formSubSection(
              getTraining(TRAINER.SELF),
              strings.pages.form.subtitle2
            )}
          </>
        ) : (
          formSubSection(section.modules)
        )}
      </div>
    </div>
  );
});
