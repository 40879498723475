export const strings = {
  pages: {
    form: {
      heading: "Nandoca Safety and Compliance Certificate",
      managerHeading: "Compliance Certificate",
      confirmHeading: "Confirm Compliance",
      confirmText1:
        "I agree, I have completed the safety and compliance training and I feel safe and compliant.",
      confirmText2:
        "I can confirm I have completed the training myself, and no one has completed this on my behalf.",
      subtitle1: "Manager Led",
      subtitle2: "Non-Manager Led",
    },
    manager: {
      heading: "Safety and Compliance Check",
      managerPage: {
        subHeading: "Management View",
        option1: "My Record",
        option2: "My Teams Compliance",
      },
      teamsCompliancePage: {
        subHeading: "My Teams Compliance",
        confirmText1: "I confirm this record to be true and accurate.",
        confirmText2:
          "I confirm that <Nandoca> has completed the safety and compliance training themselves and not on someone else's behalf.",
      },
    },
  },
  alerts: {
    formLoadError: "Failed to get form data",
    formSaveError: "Failed to save, please try again later.",
    formSaveSuccess: "Success! Your record has been updated.",
    formIncompleteError:
      "Sorry, you need to complete all the courses highlighted in red to complete your mandatory training.",
    formSubmitError: "Failed to submit, please try again.",
    formSubmitSuccess:
      "Congratulations! Your compliance certificate has been submitted.",
    formApprovedSuccess: "Success! Nandoca compliance approved.",
    formApprovedError: "Nandoca compliance has not been approved.",
  },
  common: {
    confirmUpper: "CONFIRM",
    cancelUpper: "CANCEL",
    saveUpper: "SAVE",
    submitUpper: "SUBMIT",
    pending: "Pending",
    complete: "Complete",
    approve: "APPROVE",
  },
};
