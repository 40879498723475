import authService from "../services/authService";

const uri =
  process.env.NODE_ENV === "development"
    ? "http://localhost:4000/graphql"
    : process.env.REACT_APP_API_URL + "/graphql";

const accessToken = authService.getAccessToken.bind(authService);

export const useQuery = <T,>(
  query: string,
  variables: any = null
): (() => Promise<T>) => {
  let error: boolean = false;
  let data: T;

  const getMethod = async (): Promise<T> => {
    try {
      data = await fetchQuery<T>(query, variables);
      error = false;
      return data;
    } catch (err) {
      error = true;
      throw err;
    }
  };

  return getMethod;
};

export const postQuery = <T,>(
  query: string
): ((variables: any) => Promise<T>) => {
  let error = false;
  let data: T;

  const postMethod = async <V,>(variables: V): Promise<T> => {
    try {
      data = await fetchQuery(query, variables);
      error = false;
      return data;
    } catch (err) {
      error = true;
      throw err;
    }
  };

  return postMethod;
};

const fetchQuery = async <T,>(query: String, variables: any = null) => {
  return fetch(uri, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken()}`,
    },
    body: JSON.stringify({ query, variables }),
  })
    .then((res) => res.json())
    .then((res) => {
      // GQL errors seem to be rolled up in the json response from fetch. Implementing apollo client might help: https://www.apollographql.com/docs/react/data/queries
      if (res.errors) {
        //For now detect error prop and throw
        throw new Error(res.errors[0].message);
      }
      localStorage.setItem("data", JSON.stringify(res.data));
      return res as T;
    })
    .catch((error) => {
      throw error;
    });
};
