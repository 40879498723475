import classnames from "classnames";
import "./ToastMessage.styles.css";
import ErrorIcon from "../../assets/images/ErrorIcon";
import TickIcon from "../../assets/images/TickIcon";
import CloseIcon from "../../assets/images/CloseIcon";

type ToastProps = {
  message: string;
  error?: boolean;
  isOpen: boolean;
  closeMessage: () => void;
};

export const ToastMessage = (props: ToastProps) => {
  let { message, error, isOpen, closeMessage } = props;

  return (
    <>
      {isOpen && (
        <div
          className={classnames(
            "msg-container",
            error ? "error-msg-container" : "alert-msg-container"
          )}
        >
          {error ? (
            <ErrorIcon className="msg-icon" />
          ) : (
            <TickIcon className="msg-icon" />
          )}
          <p>{message}</p>
          <CloseIcon className="close-icon" onClick={closeMessage} />
        </div>
      )}
    </>
  );
};
