import "./CustomCheckbox.style.css";

type CustomCheckbox = {
  id: number;
  text: string;
  completed?: boolean;
  conditionChecker?: boolean;
  errorText: string;
  action: () => void;
};

export const CustomCheckbox: React.FC<CustomCheckbox> = (props) => {
  const {
    text,
    completed,
    id,
    action,
    conditionChecker = false,
    errorText = "",
  } = props;

  return (
    <>
      <div
        className={"checkbox" + (conditionChecker ? " readonly" : "")}
        key={id}
      >
        <input
          type="checkbox"
          checked={completed}
          id={text}
          disabled={conditionChecker}
          onChange={action}
          data-testid="custom-checkbox-input"
        />
        <label
          data-testid="custom-checkbox-label"
          className={errorText}
          htmlFor={text}
        >
          {text}
        </label>
      </div>
    </>
  );
};
