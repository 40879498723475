import { postQuery, useQuery } from "../hooks/useQuery";
import { FormItem, FormSection, LearningObj } from "../models/FormTypes";
import {
  GraghQlResult,
  GraphQlGetTrainings,
  GraphQlTeamCertificates,
} from "../models/ServiceTypes";
import {
  SAVE_TRAININGS,
  SUBMIT_TRAININGS,
  SUBMIT_MANAGER_COMPLIANCE,
} from "../mutations/mutations";
import {
  FETCH_USER_ALL_DATA,
  FETCH_EMPLOYEE_RECORDS_ALL_DATA,
  FETCH_MEMBERS_BY_RESTAURANT_LOCATION,
} from "../queries/queries";

interface ILearningService {
  getLearnings: () => Promise<any>;
  saveLearning: (vars?: any) => Promise<any>;
  saveCertificate: () => Promise<any>;
  getEmployees: () => Promise<any>;
}

export class LearningService implements ILearningService {
  getEmployId = () => {
    const localTokenStorage = JSON.parse(
      localStorage.getItem("okta-token-storage") || ""
    );
    return localTokenStorage?.idToken?.claims?.employee_id;
  };
  getRestaurant = () => {
    const localTokenStorage = JSON.parse(
      localStorage.getItem("okta-token-storage") || ""
    );
    return localTokenStorage?.idToken?.claims?.nandos_my_restaurant;
  };

  getLearnings: () => Promise<any> = async () => {
    try {
      const getAllData = useQuery<GraghQlResult<GraphQlGetTrainings>>(
        FETCH_USER_ALL_DATA,
        { employeeId: this.getEmployId() }
      );
      const result = await getAllData();
      return {
        sections: this.parseFormData(result),
        submitted: !!result.data.employeeRecords.certificates.length,
      };
    } catch (err) {
      return err;
    }
  };

  getEmployeeRecordLearnings: (employeeId: string) => Promise<any> = async (
    employeeId: string
  ) => {
    try {
      const getAllData = useQuery<GraghQlResult<GraphQlGetTrainings>>(
        FETCH_EMPLOYEE_RECORDS_ALL_DATA,
        { employeeId }
      );
      const result = await getAllData();
      return {
        sections: this.parseFormData(result),
        submitted:
          !!result.data.employeeRecords.certificates.length &&
          !!result.data.employeeRecords.certificates[0].submittedAt,
        approved:
          !!result.data.employeeRecords.certificates.length &&
          !!result.data.employeeRecords.certificates[0].approvedAt,
        certificates: result.data.employeeRecords.certificates,
      };
    } catch (err) {
      return err;
    }
  };

  parseFormData = (trainingsData?: GraghQlResult<GraphQlGetTrainings>) => {
    if (!trainingsData) return;
    return trainingsData?.data.categories.reduce(
      (acc: any, newVal: { description: any; id: any }) => {
        return [
          ...acc,
          {
            name: newVal.description,
            modules: trainingsData.data.learnings.reduce((acc, learning) => {
              const employeeLearning = trainingsData?.data.employeeRecords
                ? trainingsData?.data.employeeRecords.learnings.find(
                    (l) => l.learningId == learning.id
                  )
                : trainingsData?.data.employeeLearnings.find(
                    (l: { learningId: any }) => l.learningId == learning.id
                  );
              return learning.categoryId == newVal.id
                ? [
                    ...acc,
                    {
                      ...learning,
                      name: learning.description,
                      description: "",
                      complete: !!employeeLearning?.completedAt,
                      error: false,
                      completedAt: employeeLearning?.completedAt
                        ? new Date(employeeLearning?.completedAt)
                        : undefined,
                      updatedAt: employeeLearning?.updatedAt
                        ? new Date(employeeLearning?.updatedAt)
                        : undefined,
                    },
                  ]
                : acc;
            }, [] as FormItem[]),
          },
        ] as FormSection[];
      },
      [] as FormSection[]
    );
  };

  saveLearning: (formData: FormSection[] | undefined) => Promise<any> = async (
    formData?: FormSection[]
  ) => {
    const storeLearnings = postQuery(SAVE_TRAININGS);
    try {
      const changedLearningIds =
        formData?.reduce((acc, val) => {
          const changedLIds = val.modules.reduce((modAcc, learningVal) => {
            const existingDate = learningVal.completedAt;
            if (learningVal.complete && !existingDate)
              learningVal.completedAt = new Date();

            if (!learningVal.complete && existingDate)
              learningVal.completedAt = undefined;

            if (existingDate != learningVal.completedAt) {
              return [
                ...modAcc,
                {
                  id: learningVal.id,
                  completedAt: learningVal.completedAt,
                },
              ];
            }
            return modAcc;
          }, [] as Array<LearningObj>);
          return [...acc, ...changedLIds];
        }, [] as Array<LearningObj>) || [];
      let result: any; //TODO - define result type
      if (changedLearningIds.length != 0) {
        result = await storeLearnings({
          employeeLearnings: {
            employeeId: await this.getEmployId(),
            learnings: changedLearningIds,
          },
        });
      }
      return result ? result?.data?.upsertEmployeeLearnings[0] : true;
    } catch (err) {
      return err;
    }
  };

  getEmployees: () => Promise<any> = async () => {
    try {
      const getAllData = useQuery<GraghQlResult<GraphQlTeamCertificates>>(
        FETCH_MEMBERS_BY_RESTAURANT_LOCATION,
        { location: await this.getRestaurant() }
      );
      const result = await getAllData();
      return result;
    } catch (err) {
      return err;
    }
  };

  saveCertificate: () => Promise<any> = async () => {
    const storeCertificate = postQuery(SUBMIT_TRAININGS);
    try {
      await storeCertificate({
        employeeCertificate: {
          employeeId: await this.getEmployId(),
          certificateId: 1,
          submittedAt: new Date(),
        },
      });
      return true;
    } catch (err) {
      return err;
    }
  };

  approveCertificate: (employeeId: string) => Promise<any> = async (
    employeeId: string
  ) => {
    const storeCertificate = postQuery(SUBMIT_MANAGER_COMPLIANCE);
    try {
      await storeCertificate({
        managerCertificate: {
          approvedBy: await this.getEmployId(),
          employeeId,
          certificateId: 1,
          approvedAt: new Date(),
        },
      });
      return true;
    } catch (err) {
      return err;
    }
  };
}
