import { SVGProps } from "react";

const BackIcon: React.FC<SVGProps<{}>> = (props) => {
  return (
    <svg
      className={props.className}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_503_3627)">
        <rect width="32" height="32" rx="16" fill="black" />
        <path
          d="M24 15H11.83L17.42 9.41L16 8L8 16L16 24L17.41 22.59L11.83 17H24V15Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_503_3627">
          <rect width="32" height="32" rx="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BackIcon;
