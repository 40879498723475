export const GET_TRAININGS = `
query EmployeeLearnings($employeeId: String) 
{  
    employeeLearnings(employeeId: $employeeId) { 
      id
      description
      completedAt
      createdAt
      isRequired
      isActive
      learningId
      trainer
      updatedAt
      employeeId
      categoryId
    }
    learnings {    
      id
      description
      categoryId
      isActive
      isRequired
      trainer
      category {
        id
        description
      } 
  }  
  categories {    
      id    
      description  
  }
}
`;

export const FETCH_USER_ALL_DATA = `
query EmployeeRecords($employeeId: String) {
  employeeRecords(employeeId: $employeeId) {
    employeeId
    learnings {
      id
      description
      completedAt
      createdAt
      isRequired
      isActive
      learningId
      trainer
      updatedAt
      employeeId
      categoryId
    }
    certificates {
      id
      description
    }
  }
  learnings {
    id
    description
    categoryId
    isActive
    isRequired
    trainer
    category {
      id
      description
    }
  }
  categories {
    id
    description
  }
}
`;

export const FETCH_MEMBERS_BY_RESTAURANT_LOCATION = `
query TeamCertificate($location: String!) {  
  teamCertificates(location: $location) {    
    id
    first_name_legal
    first_name_preferred
    last_name_legal
    last_name_preferred
    certificates {
      id
      description
      approvedAt
      submittedAt
    }
  }
}
`;

export const FETCH_EMPLOYEE_RECORDS_ALL_DATA = `
query EmployeeRecords($employeeId: String) {
  employeeRecords(employeeId: $employeeId) {
    employeeId
    learnings {
      id
      description
      completedAt
      createdAt
      isRequired
      isActive
      learningId
      trainer
      updatedAt
      employeeId
      categoryId
    }
    certificates {
      id
      description
      approvedAt
      submittedAt
    }
  }
  learnings {
    id
    description
    categoryId
    isActive
    isRequired
    trainer
    category {
      id
      description
    }
  }
  categories {
    id
    description
  }
}
`;
