import { SVGProps } from "react";

const TickIcon: React.FC<SVGProps<{}>> = (props) => {
  return (
    <svg
      className={props.className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="12" fill="#4A823D" />
      <path
        d="M9.08471 15.4676L5.29164 11.736L4 12.9978L9.08471 18L20 7.26174L18.7175 6L9.08471 15.4676Z"
        fill="white"
      />
    </svg>
  );
};

export default TickIcon;
