import "./CustomButton.style.css";

type CustomButton = {
  className?: string;
  text: string;
  disabled?: boolean;
  action: () => void;
};

export const CustomButton = ({
  text,
  className,
  disabled,
  action,
}: CustomButton) => {
  return (
    <>
      <button className={className} disabled={disabled} onClick={action}>
        {text}
      </button>
    </>
  );
};
