/* eslint-disable array-callback-return */
import React from "react";
import { FormSection, FormItem } from "../../models/FormTypes";
import * as Styles from "../Page.styles";
import "./FormPage.css";
import { SectionTable } from "../../components/form/FormSection";
import { ToastMessage } from "../../components/message/ToastMessage";
import { convertTimeToSavedLearningDate } from "../../services/time";
import { returnTime } from "../../services/time";
import { strings } from "../../assets/res/strings";
import { LearningService } from "../../services/learningService";
import { CustomButton } from "../../components/button/CustomButton";
import { CustomCheckbox } from "../../components/checkbox/CustomCheckbox";
import { ButtonPage } from "../../components/buttonPage/ButtonPage";
import { ButtonEnum } from "../../components/buttonPage/buttonType";
import { isManager } from "../../components/RequiresAuth";
import Spinner from "../../components/spinner/Spinner";
const ManagerPage = "../manager";

const getLatestSavedLearningDate = (form?: FormSection[]) => {
  let date;
  if (!form || !form.length) return;
  for (let i = 0; i < form.length; i++) {
    for (let j = 0; j < form[i].modules.length; j++) {
      const newDate = form[i].modules[j].updatedAt ?? undefined;
      if (!!newDate) {
        date = date ?? newDate;
        date = newDate > date ? newDate : date;
      }
    }
  }
  return date ? new Date(date) : undefined;
};

export const FormPage = () => {
  const [formData, setFormData] = React.useState<Array<FormSection>>();
  const [showConfirmation, setShowConfirmation] = React.useState<boolean>();
  const [submitted, setSubmitted] = React.useState(false);
  const [showSave, setShowSave] = React.useState(false);
  const [submitError, setSubmitError] = React.useState(false);
  const [toast, setToast] = React.useState<{
    message: string;
    error: boolean;
    isOpen: boolean;
  }>({ message: "", error: false, isOpen: false });
  const [lastSaved, setLastSaved] = React.useState<{
    date: string;
    time: string;
  }>({ date: "", time: "" });

  const lService = new LearningService();

  React.useEffect(() => {
    (async () => {
      const result = await lService.getLearnings();
      setFormData(result.sections);
      setSubmitted(result.submitted);
      const sDate = getLatestSavedLearningDate(result.sections);
      if (sDate) {
        setLastSaved({
          date: convertTimeToSavedLearningDate(sDate),
          time: returnTime(sDate),
        });
        //if we have a last saved date, we know we can save
        setShowSave(true);
      }
    })();
  }, []);

  const resetToast = () => {
    setToast({
      message: "",
      error: false,
      isOpen: false,
    });
  };

  const [confirmChecklist, setConfirmChecklist] = React.useState([
    {
      id: 1,
      text: strings.pages.form.confirmText1,
      completed: false,
    },
    {
      id: 2,
      text: strings.pages.form.confirmText2,
      completed: false,
    },
  ]);

  const completeRequiredTrainingSection = React.useMemo<{
    [key: string]: boolean;
  }>(() => {
    return (
      formData?.reduce((acc, sectionGroup) => {
        return {
          ...acc,
          [sectionGroup.name]: sectionGroup.modules.reduce(
            //@ts-ignore
            (boolReq, formItem) => {
              if (formItem.isRequired && !formItem.complete) {
                setSubmitted(false);
                return false;
              }
              return boolReq;
            },
            true
          ),
        };
      }, {}) || {}
    );
  }, [formData]);

  const requiredTrainingComplete = React.useMemo(() => {
    return Object.values(completeRequiredTrainingSection).every((bool) => bool);
  }, [completeRequiredTrainingSection]);

  const confirmValid = React.useMemo(
    () => confirmChecklist.every((val) => val.completed),
    [confirmChecklist]
  );
  const scrollToSection = () => {
    const section = document.querySelector("#message");
    section?.scrollIntoView({ behavior: "smooth" });
  };

  const onSave = React.useCallback(
    async (silent: boolean = false) => {
      resetToast();
      setSubmitError(false);

      let saveRes = await lService.saveLearning(formData);
      if (saveRes) {
        if (saveRes?.updatedAt) {
          setLastSaved({
            date: convertTimeToSavedLearningDate(new Date(saveRes.updatedAt)),
            time: returnTime(new Date(saveRes.updatedAt)),
          });
        }
        setToast({
          message: strings.alerts.formSaveSuccess,
          error: false,
          isOpen: !silent,
        });
      } else {
        // Error message
        setToast({
          message: strings.alerts.formSaveError,
          error: true,
          isOpen: !silent,
        });
      }
      scrollToSection();
    },
    [formData]
  );

  const onSubmit = React.useCallback(() => {
    resetToast();
    if (!requiredTrainingComplete) {
      setToast({
        message: strings.alerts.formIncompleteError,
        error: true,
        isOpen: true,
      });
      setSubmitError(true);
      setSubmitted(false);
      scrollToSection();
      return;
    }

    setShowConfirmation(true);
  }, [requiredTrainingComplete]);

  const saveCertificate = React.useCallback(async () => {
    let saveRes = await lService.saveCertificate();
    if (saveRes === true) {
      setToast({
        message: strings.alerts.formSubmitSuccess,
        error: false,
        isOpen: true,
      });
      setSubmitted(true);
    } else {
      handleCancelClick(); // to make greyed out trainings to black once Confirm fails
      setToast({
        message: strings.alerts.formSubmitError,
        error: true,
        isOpen: true,
      });
      setSubmitted(false);
    }
  }, [formData]);

  const onConfirm = React.useCallback(async () => {
    if (confirmValid) {
      await onSave(true).then(async () => {
        await saveCertificate();
      });

      setShowConfirmation(false);
    }
  }, [confirmValid]);

  const handleClick = React.useCallback(
    (module: FormItem) => {
      const newData = JSON.parse(JSON.stringify(formData));
      const { modules } = newData[module.categoryId - 1];
      const index = modules.findIndex(({ id }: FormItem) => module.id === id);
      modules[index].complete = !module.complete;
      modules[index].error = false;

      setShowSave(true);
      setFormData(newData);
    },
    [formData]
  );

  const handleConfirmChecked = React.useCallback(
    (item: (typeof confirmChecklist)[0]) => {
      setConfirmChecklist((values) =>
        values.map((aItem) => {
          if (aItem.id == item.id) {
            return { ...aItem, completed: !aItem.completed };
          }
          return aItem;
        })
      );
    },
    [setConfirmChecklist]
  );

  const handleCancelClick = React.useCallback(() => {
    setConfirmChecklist((values) =>
      values.map((aItem) => {
        return { ...aItem, completed: false };
      })
    );
    setSubmitted(false);
    setShowConfirmation(false);
  }, [setConfirmChecklist]);

  return (
    <>
      {/* POPUP */}
      {showConfirmation && requiredTrainingComplete && (
        <>
          <div className="confirm-section">
            <div className="confirm-section-content">
              <div className="confirm-section-header">
                <p>{strings.pages.form.confirmHeading}</p>
              </div>
              <div className="confirm-checkboxes">
                {confirmChecklist.map((item: any) => {
                  const { text, completed, id } = item;
                  return (
                    <CustomCheckbox
                      id={id}
                      text={text}
                      completed={completed}
                      action={() => handleConfirmChecked(item)}
                      conditionChecker={false}
                      errorText={""}
                    />
                  );
                })}
              </div>
            </div>
            <div className="confirm-buttons-section">
              <CustomButton
                text={strings.common.confirmUpper}
                className={"btn-confirm" + (confirmValid ? "" : " disabled")}
                disabled={!confirmValid}
                action={onConfirm}
              />
              <CustomButton
                text={strings.common.cancelUpper}
                className="btn-cancel"
                action={handleCancelClick}
              />
            </div>
          </div>
          <div className="overlay"></div>
        </>
      )}
      {/* END POPUP */}
      {/* TODO - add dynamic header */}
      <div
        className={
          "page-container" + (showConfirmation ? " tablet-desktop-only" : "")
        }
      >
        <h1 style={Styles.Title}>{strings.pages.form.heading}</h1>
        <div className={"nav-link-container-form"}>
          {isManager() ? (
            <ButtonPage
              text={undefined}
              page={ManagerPage}
              buttonType={ButtonEnum.backButton}
            />
          ) : undefined}
          {lastSaved.date && lastSaved.time && (
            <p className={"last-saved-date"}>
              Last saved on{" "}
              <span className="last-saved-date">{lastSaved.date}</span> at
              <span className="last-saved-date">{lastSaved.time}</span>
            </p>
          )}
        </div>
        {!formData ? (
          <Spinner />
        ) : (
          <div id="message" className="section-tables-wrapper">
            <ToastMessage
              message={toast.message}
              error={toast.error}
              isOpen={toast.isOpen}
              closeMessage={() => setToast({ ...toast, isOpen: false })}
            />
            <div className="section-tables">
              {formData &&
                formData?.map((section: FormSection) => (
                  <SectionTable
                    error={
                      submitError &&
                      !completeRequiredTrainingSection[section.name]
                    }
                    submitted={submitted}
                    section={section}
                    handleClick={handleClick}
                    confirmed={confirmValid}
                    managerView={false}
                  />
                ))}
            </div>
            <div className="submit-buttons-wrapper">
              <div
                className={
                  "section-table-container" +
                  (submitted ? " save-btn-only" : " submit-buttons")
                }
              >
                <CustomButton
                  text={strings.common.saveUpper}
                  className={showSave ? " btn-save" : " save-disabled"}
                  disabled={!showSave}
                  action={async () => {
                    onSave();
                  }}
                />
                <CustomButton
                  text={strings.common.submitUpper}
                  className={submitted ? " submit-btn-hidden" : " btn-submit"}
                  disabled={submitted}
                  action={onSubmit}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
