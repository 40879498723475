import BackIcon from "../../assets/images/BackIcon";
import ChevronIcon from "../../assets/images/formPageImages/ChevronIcon";

export enum ButtonEnum {
  chevronButton = 0,
  backButton,
}
export const buttons = [
  {
    name: "chevronButton",
    className: "button-with-chevron text-label",
    img: <ChevronIcon className="button-chevron" />,
  },
  {
    name: "backButton",
    className: "",
    img: <BackIcon className="nav-item" />,
  },
];
