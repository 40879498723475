import { OktaAuth } from "@okta/okta-auth-js";

const OKTA_CLIENT_ID = process.env.REACT_APP_OKTA_CLIENT_ID;
const OKTA_ORG_DOMAIN = process.env.REACT_APP_OKTA_ORG_DOMAIN;
const OKTA_AUTH_SERVER = process.env.REACT_APP_OKTA_AUTH_SERVER || "default";
const OKTA_REDIRECT_PROTOCOL =
  process.env.REACT_APP_OKTA_REDIRECT_PROTOCOL || "http";
const REDIRECT_DOMAIN = process.env.REACT_APP_OKTA_REDIRECT_DOMAIN;

export default new OktaAuth({
  issuer: `https://${OKTA_ORG_DOMAIN}/oauth2/${OKTA_AUTH_SERVER}`,
  clientId: OKTA_CLIENT_ID,
  redirectUri: `${OKTA_REDIRECT_PROTOCOL}://${REDIRECT_DOMAIN}/login/callback`,
  scopes: ["openid", "employee_details", "nandos"],
});
