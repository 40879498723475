import React from "react";
import Header from "../../components/manager/Header";
import "../form/FormPage.css";
import "./ManagerPage.styles.css";
import FormPage from "./FormPage";

const EmployeeCompliance = () => {
  return (
    <>
      {/* <Header /> */}
      <FormPage />
    </>
  );
};

export default EmployeeCompliance;
