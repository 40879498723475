import React from "react";
import Header from "../../components/manager/Header";
import "../form/FormPage.css";
import "./ManagerPage.styles.css";
import { CustomToggle } from "../../components/toggle/CustomToggle";
import { TeamCertificate, Certificate } from "../../models/ServiceTypes";
import { ButtonEnum } from "../../components/buttonPage/buttonType";
import { ButtonPage } from "../../components/buttonPage/ButtonPage";
import { strings } from "../../assets/res/strings";
import { useEffect, useState } from "react";
import { LearningService } from "../../services/learningService";
import { useLocation } from "react-router-dom";
import { ToastMessage } from "../../components/message/ToastMessage";
import { Spinner } from "../../components/spinner/Spinner";
const ManagerPage = "/manager";

export const TeamsCompliancePage = () => {
  const [teamCertificates, setTeamCertificates] =
    useState<Array<TeamCertificate>>();
  const [subsetCerts, setSubsetCerts] = useState<Array<TeamCertificate>>();
  const [pending, setPending] = useState<boolean>(true);
  const [toast, setToast] = React.useState<{
    message: string;
    error: boolean;
    isOpen: boolean;
  }>({ message: "", error: false, isOpen: false });
  const location = useLocation();
  const { state } = location;

  const lService = new LearningService();
  useEffect(() => {
    (async () => {
      if (state) {
        if (state.success == true) {
          setPending(false);
          setToast({
            message: strings.alerts.formApprovedSuccess,
            error: false,
            isOpen: true,
          });
        } else {
          setPending(true);
          setToast({
            message: strings.alerts.formApprovedError,
            error: true,
            isOpen: true,
          });
        }
      }
      const { data } = await lService.getEmployees();
      const memberSubmitted = data.teamCertificates.filter(
        (member: { certificates: Array<Certificate> }) =>
          member.certificates.length > 0
      );
      setTeamCertificates(memberSubmitted);
    })();
  }, [state]);

  useEffect(() => {
    if (teamCertificates) {
      setSubsetCerts(toggleList(teamCertificates));
    }
  }, [pending, teamCertificates]);

  const findCert = (certificate: Array<Certificate>) =>
    certificate.find((c) => c.description === "Nandoca Safety and Compliance");

  const toggleList = (certificates: TeamCertificate[]): TeamCertificate[] => {
    if (pending) {
      return certificates.filter(
        (c) =>
          findCert(c.certificates)?.submittedAt &&
          !findCert(c.certificates)?.approvedAt
      );
    } else {
      return certificates.filter(
        (c) =>
          findCert(c.certificates)?.submittedAt &&
          findCert(c.certificates)?.approvedAt
      );
    }
  };

  return (
    <>
      <div className="page-container">
        <Header
          className={""}
          heading={strings.pages.manager.heading}
          containerClassName={"nav-link-container"}
          page={ManagerPage}
          subHeadingClassName={"nav-item"}
          subHeading={strings.pages.manager.teamsCompliancePage.subHeading}
          date={undefined}
        />
        <ToastMessage
          message={toast.message}
          error={toast.error}
          isOpen={toast.isOpen}
          closeMessage={() => setToast({ ...toast, isOpen: false })}
        />
        <CustomToggle
          lToggle={state ? !state.success : true}
          action={() => setPending(!pending)}
        />
        {!teamCertificates ? (
          <Spinner />
        ) : (
          subsetCerts?.map((tCertificate: TeamCertificate) => (
            <ButtonPage
              text={
                tCertificate.first_name_preferred &&
                tCertificate.last_name_preferred
                  ? tCertificate.first_name_preferred +
                    " " +
                    tCertificate.last_name_preferred
                  : tCertificate.first_name_legal +
                    " " +
                    tCertificate.last_name_legal
              }
              page={`/manager/teamsCompliance/nandocaForm/${tCertificate.id}`}
              state={{
                firstName:
                  tCertificate.first_name_preferred ||
                  tCertificate.first_name_legal,
                lastName:
                  tCertificate.last_name_preferred ||
                  tCertificate.last_name_legal,
              }}
              buttonType={ButtonEnum.chevronButton}
            />
          ))
        )}
      </div>
    </>
  );
};
