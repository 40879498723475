import { createContext, useState, useEffect } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { customClaimsMap, CustomClaims } from "./MapClaims";

//TODO - Review the use of this context as it might be redundant

interface props {
  children: JSX.Element | JSX.Element[];
}
interface AuthContextProps {
  userInfo: CustomClaims | undefined;
}

export const AuthContext = createContext<AuthContextProps>({
  userInfo: undefined,
});

export const AuthProvider = ({ children }: props) => {
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState<CustomClaims>();

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      setUserInfo(undefined);
    } else if (authState?.idToken?.claims) {
      customClaimsMap(authState.idToken.claims).then((claims) => {
        setUserInfo(claims);
      });
    }
  }, [authState, oktaAuth]);

  return (
    <AuthContext.Provider value={{ userInfo }}>{children}</AuthContext.Provider>
  );
};
