import { SVGProps } from "react";

const ChevronIcon: React.FC<SVGProps<{}>> = (props) => {
  return (
    <svg
      className={props.className}
      width="8"
      height="12"
      viewBox="0 0 8 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.119995 1.41L4.51679 6L0.119995 10.59L1.4736 12L7.23359 6L1.47359 -5.91677e-08L0.119995 1.41Z"
        fill="black"
      />
    </svg>
  );
};

export default ChevronIcon;
