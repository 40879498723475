import React from "react";
import "./App.css";
import { Routes, Route, useNavigate } from "react-router-dom";
import { FormPage } from "./pages/form/FormPage";
import { ManagerPage } from "./pages/manager/ManagerPage";
import { TeamsCompliancePage } from "./pages/manager/TeamsCompliancePage";
import EmployeeCompliance from "./pages/manager/EmployeeCompliance";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { LoginCallback, Security } from "@okta/okta-react";
import { RequiresAuth, isManager } from "./components/RequiresAuth";
import authService from "./services/authService";
import { AuthProvider } from "./context/AuthContext";

export const App = () => {
  const navigate = useNavigate();

  const restoreOriginalUri = async (_: OktaAuth, originalUri: string) => {
    navigate(
      toRelativeUrl(
        isManager() ? "/manager" : originalUri,
        window.location.origin
      ),
      {
        replace: true,
      }
    );
  };

  return (
    <div className="App">
      <Security oktaAuth={authService} restoreOriginalUri={restoreOriginalUri}>
        <AuthProvider>
          <Routes>
            <Route path="/login/callback" element={<LoginCallback />} />
            <Route element={<RequiresAuth />}>
              <Route path="/" element={<FormPage />} />
              <Route path="/manager">
                <Route index={true} element={<ManagerPage />} />
                <Route path="teamsCompliance">
                  <Route index={true} element={<TeamsCompliancePage />} />
                  <Route
                    path="nandocaForm/:userId"
                    element={<EmployeeCompliance />}
                  />
                </Route>
              </Route>
            </Route>
          </Routes>
        </AuthProvider>
      </Security>
    </div>
  );
};
